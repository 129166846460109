<template>
</template>

<script>
import options from '@/api/options';
import { mapActions } from 'vuex';

export default {
    name: 'B24Chat',
    data(){
        return {
            code: null
        }
    },
    methods:{
        ...mapActions('user', ['getCurUser']),
        async init(){
            await this.getCode();
            if(this.code)
                this.initChat();
            else{
                this.$emit('no-chat');
            }
        },
        async getCode(){
            return fetch('/api/options/b24chat/')
            .then(res => res.json())
            .then(res => {
                if(res.result){
                    const map = {
                        '&': '&amp;',
                        '<': '&lt;',
                        '>': '&gt;',
                        '"': '&quot;',
                        "'": '&#039;'
                    };
                    
                    this.code = res.result.replace(/[&<>"']/g, function(m) { return map[m]; });
                }
            })
        },
        async getCorrectUrl(){
            let portal = await options.getPortal();
            if(portal.match(/bitrix24.ru/)) portal = 'cdn-ru.bitrix24.ru';

            let url = `https://${portal}/${this.code}.js`;
            return url;
        },
        async initChat(){
            const cuser = await this.getCurUser();
            const url = await this.getCorrectUrl();
            (function(w,d,u){
                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
            })(window,document,url);
            
            window.addEventListener('onBitrixLiveChat', function(event)
            {
                const widget = event.detail.widget;

                // Установка внешней авторизации пользователя
                widget.setUserRegisterData({
                    'hash': cuser.HASH,
                    'name': cuser.NAME,
                    'lastName': cuser.LAST_NAME,
                    'email': cuser.EMAIL,
                });

                widget.setCustomData([
                    {
                        "USER": {
                            "NAME" : `${cuser.NAME} ${cuser.LAST_NAME}`,
                        }
                    },
                    {
                        "GRID": [
                            {
                                "NAME" : "ID",
                                "VALUE" : cuser.ID,
                                "DISPLAY" : "LINE",
                            },
                            {
                                "NAME" : "Тип клиента",
                                "VALUE" : cuser.TYPE,
                                "DISPLAY" : "LINE"
                            },
                        ]
                    }
                ])
            
            });
        }
    },
    mounted(){
        this.init();
    }
}
</script>

<style>
.b24-widget-button-wrapper{
    display: none!important;
}
</style>