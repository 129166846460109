<template>
  <div class="main-container">
    <div class="main-content">
      <template v-if="isAuthComp">
        <Header />
        <main class="content">
          <router-view/>
        </main>
        <Footer />
      </template>
      <template v-else>
        <Auth />
      </template>
    </div>
  </div>
</template>
<script>
import Header from '@/components/blocks/Header.vue';
import Footer from './components/blocks/Footer.vue';
import users from './api/users';
import Auth from './components/pages/Auth.vue';
import '@/libs/general';
import { isAuth } from '@/libs/general';
import { mapActions } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    Auth,
  },
  data(){
    return{
      isAuth: false
    }
  },
  computed:{
    isAuthComp(){
      return isAuth.value;
    }
  },
  methods:{
    ...mapActions('general', ['getTitle', 'getTheme']),
    ...mapActions('options', ['getBaseOption']),
  },
  async mounted(){
    const isAuth_ = await users.isAuth();
    
    if(isAuth_.status && isAuth_.result){
      //auth success
      isAuth.value = true;
    } else {
      //auth failed
      isAuth.value = false;
    }
    const title = await this.getTitle();
    if(title){
      document.title = title;
    }
    const theme = await this.getTheme();
    if(theme){
      document.querySelector('[name="theme-color"]').content = theme;
    } else {
      document.querySelector('[name="theme-color"]').content = '#ff9800'
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;700&display=swap');
html, body, #app{
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #ededed;
}
#app{
  display: grid;
  grid-template-areas:
    "A A A A   A A A A   A A A A"
    "C C C C   C C C C   C C C C";
  grid-template-columns: repeat(12,1fr);
  padding: 0 20px;
}
.content{
  grid-area: C;
}
.block{
  background-color: #fff;
  border-radius: 15px;
  padding: 40px 20px;
  margin-bottom: 20px;
}
.order{
  margin-bottom: 15px;
  display: block;
  align-items: baseline;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}
.link{
  color: black;
}
@media screen and (min-width: 768px) {
  #app{
    grid-template-areas:
    "A A A A   A A A A   A A A A"
    "B C C C   C C C C   C C C C";
    grid-template-columns: repeat(12,1fr);
  }
  .main-content{
    width: 768px;
    margin: 0 auto;
  }
  .main-content > .header{
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 20px;
  }
  #app .main-content .wrapper{
    padding: 0;
  }
  .main-content > .footer{
    width: 768px;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
}
</style>
