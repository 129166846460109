export default {
    base_url: '/',
    getOption(name){
        return fetch(`${this.base_url}api/options/${name}/`)
            .then(res => res.json())
            .then(res => res.result);
    },
    getBaseOption(name){
        return fetch(`${this.base_url}api/options/baseParams/`)
            .then(res => res.json())
            .then(res => {
                if(!res.status || !res.result) return null;
                let data = JSON.parse(res.result);
                if(data === null) data = {};
                return data[name];
            });
    },
    getBaseOptions(){
        return fetch(`${this.base_url}api/options/baseParams/`)
            .then(res => res.json())
            .then(res => {
                if(!res.status || !res.result) return null;
                let data = JSON.parse(res.result);
                if(data === null) data = {};
                return data;
            });
    },
    getEnumVals(codes){
        const fd = new FormData();
        codes.forEach(code => fd.append('codes[]', code));
        return fetch(`${this.base_url}api/options/enums/`, {
                method: 'POST',
                body: fd
            })
            .then(res => res.json());
    },
    getEnumValsPay(codes){
        const fd = new FormData();
        codes.forEach(code => fd.append('codes[]', code));
        return fetch(`${this.base_url}api/options/enums-pay/`, {
                method: 'POST',
                body: fd
            })
            .then(res => res.json());
    },
    getPortal(){
        return fetch(`${this.base_url}api/portal`)
            .then(res => res.json())
            .then(res => res.result);
    }
}